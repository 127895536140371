
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const store = useStore();
    const isDarkThemeOn = computed(() => store.getters.stateOfDarkTheme);
    return {
      isDarkThemeOn,
    };
  },
});
